import React, { useEffect, useState } from 'react'
import SectionContainer from '../shared/SectionContainer'
import SectionTitle from '../shared/SectionTitle'
import Styled from 'styled-components'
import Mobile from './Mobile'
import { graphql, useStaticQuery } from 'gatsby'

// const data = [
// 	{
// 		id: 1,
// 		image: kidKlaipėda,
// 		title: 'Savanoriai vaikams ligoninėse',
// 		content: 'Mūsų tikslas - kad nė vienas vaikas ligoninėje neliktų vienišas. Iniciatyvos "Niekieno vaikai" savanoriai ligoninėse rūpinasi vienišais vaikais, kurie yra palikti be tėvų ar kitų teisėtų atstovų priežiūros.',
// 	},
// 	{
// 		id: 2,
// 		image: kidKlaipėda,
// 		title: 'Savanoriai vaikams ligoninėse',
// 		content: 'Mūsų tikslas - kad nė vienas vaikas ligoninėje neliktų vienišas. Iniciatyvos "Niekieno vaikai" savanoriai ligoninėse rūpinasi vienišais vaikais, kurie yra palikti be tėvų ar kitų teisėtų atstovų priežiūros.',
// 	},
// 	{
// 		id: 3,
// 		image: kidKlaipėda,
// 		title: 'Savanoriai vaikams ligoninėse',
// 		content: 'Mūsų tikslas - kad nė vienas vaikas ligoninėje neliktų vienišas. Iniciatyvos "Niekieno vaikai" savanoriai ligoninėse rūpinasi vienišais vaikais, kurie yra palikti be tėvų ar kitų teisėtų atstovų priežiūros.',
// 	}
// ]

export const query = graphql`
  {
    allStrapiSavanoriaiVaikams {
      edges {
        node {
          SavanoriaiTitle
          Savanoryste {
            antraste
            aprasymas
            id
            nuotrauka {
              localFile {
                publicURL
              }
            }
          }
        }
      }
    }
  }
`
interface items {
	key: number;
	id: number,
	nuotrauka: string,
	antraste: string,
	aprasymas: string,
}

const Volenteer = () => {
	const data = useStaticQuery(query)

	const savanoriaiTitle = data.allStrapiSavanoriaiVaikams.edges[0].node.SavanoriaiTitle
	const savanoryste = data.allStrapiSavanoriaiVaikams.edges[0].node.Savanoryste

	const [showItem, setShowClickedItem] = useState(savanoryste[0]?.id)
	const [showData, setShowData] = useState(false)

	function handleClick(id: number) {
		setShowData(!showData)
		setShowClickedItem(id)
	}

	function findActiveElement(id: number) {
		return savanoryste.find((item: any) => item.id === id);
	}

	const isBrowser = typeof window !== 'undefined'
	const [isMobile, setIsMobile] = useState(isBrowser ? window.innerWidth < 768 : false)

	useEffect(() => {
		function handleResize() {
			setIsMobile(window.innerWidth < 768);
		}
		window.addEventListener('resize', handleResize)
	})

	return (
		<SectionContainer style={{ padding: '32px' }} >
			<div id="programos" style={{ display: 'flex', flexDirection: 'column' }}>
				<SectionTitle> {savanoriaiTitle} </SectionTitle>
				<Container >
					<Img src={findActiveElement(showItem)?.nuotrauka.localFile.publicURL}></Img>
					<ContentWrapper>
						{savanoryste.map((item: items, index: number) =>
							<Title onClick={() => handleClick(item.id)} key={index}
								style={{ opacity: showItem === item.id ? 1 : 0.5 }}>
								{item.antraste}
								{item.id === showItem && (<Content> {item.aprasymas} </Content>)}
							</Title>
						)}
					</ContentWrapper>
				</Container>
			</div>
		</SectionContainer >
	)
}

export default Volenteer

const Container = Styled.div`
    display: flex;
	gap: 30px;

	@media (max-width: 950px) {
		flex-direction: column;
		align-items: center;
	}
`
const Img = Styled.img`
    // flex: 1;
    max-width: 700px;
	width: 100%;
	margin: 0;

`
const ContentWrapper = Styled.div`
	display: flex;
    flex-direction: column;
    justify-content: center;
`
const Title = Styled.h3`
    border-left: 3px solid #0E558F;
    font-size: 24px;
	color:#0E558F;
	font-weigth: 500;
	margin: 16px 0;
	padding: 0 0 0 20px; 
	cursor: pointer;
`
const Content = Styled.div`
	color: #0E558F;
    font-size: 16px;
    font-weight: 400;
    flex: 1 1 565px;
	margin: 16px 0;
`