import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import TeamCard from './TeamCard';
import img from '../../assets/img/OurTeam/ievaSuipe.png';
import ImgArrrowRight from '../../assets/svg/SavArrowRight.svg';
import ImgArrowLeft from '../../assets/svg/SavArrowLeft.svg';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import SectionTitle from '../shared/SectionTitle';
import Gradient from './Gradient'
import { graphql, useStaticQuery } from 'gatsby';
import OurTeamMobileVersion from './OurTeamMobileVersion';

interface LineWidht {
	lineWidhtProp: string;
}

const query = graphql`
  {
    allStrapiSavanoriaiVaikams {
      edges {
        node {
          MusuKomandaTitle
          Komanda {
            Pareigos
            VardasPavarde
            id
            nuotrauka {
              localFile {
                publicURL
                id
              }
            }
          }
        }
      }
    }
  }
`
const OurTeam = () => {

	const content = useStaticQuery(query)
	const title = content.allStrapiSavanoriaiVaikams.edges[0].node.MusuKomandaTitle
	const data = content.allStrapiSavanoriaiVaikams.edges[0].node['Komanda']

	const [currentSlideVariable, setcurrentSlideVariable] = useState(0);

	useEffect(() => {
		calculateLineWidth();
	}, [currentSlideVariable])

	function calculateLineWidth() {
		const dataLenght = data.length;
		const maxLineWidth = 447;
		return `${currentSlideVariable / dataLenght * maxLineWidth}px`;
	}

	const config = {
		infinite: true,
		speed: 500,
		slidesToShow: 4,
		slidesToScroll: 1,
		arrows: true,
		nextArrow: <img src={ImgArrrowRight} />,
		prevArrow: <img src={ImgArrowLeft} />,
		beforeChange: (current: any) => setcurrentSlideVariable(current),
		responsive: [
			{
				breakpoint: 1228,
				settings: {
					slidesToShow: 3,
					slidesToScroll: 1,
					infinite: true,
					dots: false,
				}
			},
			{
				breakpoint: 911,
				settings: {
					slidesToShow: 2,
					slidesToScroll: 1,
					infinite: true,
					dots: false,
				}
			},
			{
				breakpoint: 780,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1,
					initialSlide: 2,
				}
			},
		]
	};
	const isBrowser = typeof window !== "undefined"
	const [settings, setSettings] = useState(config);
	const [isMobile, setIsMobile] = useState(isBrowser ? window.innerWidth < 768 : false);
	const [showComponent, setShowComponent] = useState(true)
	const [visible, setVisible] = useState(4);

	const handleClickFunction = () => {
		setVisible((prevValue) => (prevValue + (data.length - prevValue)))
		setShowComponent(false);
	}

	useEffect(() => {
		function handleResize() {
			setIsMobile(window.innerWidth < 768);
		}
		window.addEventListener('resize', handleResize)
	})

	// if (isMobile) return <OurTeamMobileVersion data={data} title={title} visible={visible} showComponent={showComponent} handleClickFunction={handleClickFunction} />

	return (
		// <></>
		<Container>
			<SectionTitle>{title}</SectionTitle>
			<Slider {...settings}>
				{data.map((item: any) => (
					<TeamCard
						key={item.id}
						name={item.VardasPavarde}
						position={item.Pareigos}
						image={item.nuotrauka.localFile.publicURL}
					/>
				))}
			</Slider>

			<div style={{ display: 'flex', margin: '32px 0 64px' }}>
				{!isMobile && <div style={{ flex: 2 }}></div>}
				<div style={{ flex: 1 }}>
					<Overlay lineWidhtProp={calculateLineWidth()}></Overlay>
					<Line></Line>
				</div>
			</div>
		</Container>
	);
}

export default OurTeam

const Container = styled.div`
	max-width: 1160px; 
	width: 100%;
	margin: 0 auto;
	padding: 0 32px;

	h2 {
		font-size: 32px;
		font-weight: 600;
		text-align: center;
		margin: 64px 16px 48px 16px;	
		color: #0E558F;
	}

	@media (max-width: 768px) {
		flex-direction: column;
		align-items: center;
		max-width: 100%;
		height: auto;
		margin: 16px;
	}
`

const Overlay = styled.div<LineWidht>`
	width: ${(props: any) => props?.lineWidhtProp || '446px'};
	height: 3px;
	margin-bottom: -3px;
	background-color: #3F8ABB;	
`
const Line = styled.div`
	flex: 1 1 446px;
	max-width: 446px;
	height: 3px;
	background-color: #3F8ABB;
	opacity: 0.3;
`
