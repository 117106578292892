import React from 'react'
import styled from 'styled-components';

const SectionTitle = ({ children }: any) => (
    <Title>{children}</Title>
);

export default SectionTitle;

const Title = styled.h2`
	margin: 32px auto;
	text-align: center;
	font-weight: 600;
	color: #0E558F;

	@media (max-width: 768px) {
		font-size: 32px;
	}
`
