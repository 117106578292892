import React from 'react'
import styled from 'styled-components'; 

interface Props {
  name: string;
  position: string;
  image: string;
}

const TeamCard = ({ name, position, image }: Props ) => {
    return ( 
        <StyledCard>
          <Image 
            src={ image }
            alt={`Mūsų komanda ${ name }`}
          />
          <div>
            <Name>{ name }</Name>
            <Position>{ position }</Position>
          </div>
        </StyledCard>
      )
}

export default TeamCard

const Name= styled.div`
  color: #202020;
  font-size: 20px;
  font-weight: 700;
  text-align: center;
  margin: 16px 0;
  width: 267px;
`

const Position = styled.div`
  margin: 0 16px 15px; 
  font-weight: 400;
  font-size: 16px;
  text-align: center;
  color: #202020;
`

const Image = styled.img`
  max-width: 268px;
  max-heigth: 268px;
`

const StyledCard = styled.div`
  display: flex; 
  flex: 1 1 268px;
  flex-direction: column;
  align-items: center;
  margin: 0 16px;
  align-items: center;


  @media (max-width: 768px) {
    flex: 1 1 auto;
    width: 100%;
    height: auto;
    margin: 0;
    padding: 0;
  }
`