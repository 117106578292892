import React from 'react'
import Styled from 'styled-components'

interface Props {
	img: string,
	colorProp: string,
	btnText: string,
	url?: string,
}

const Card = ({ url, img, colorProp, btnText }: Props) => {

	return (
		<>
			<Container style={{
				flex: '1 0 300px',
				border: `2px solid ${colorProp}`,
				
			}}>
				<Img src={img} style={{margin: 'auto 0',
				maxWidth: '300px',
				background: 'white'}} />
				<Button onClick={() => window.open(url)} style={{ backgroundColor: colorProp }}>{btnText}</Button>
			</Container>
		</>
	)
}

export default Card

const Container = Styled.div`
	display: flex;
	flex-direction: column;
	padding-top: 16px;

	width: 100%;
	/* height: 240px; */
	border: 1px solid #0E558F;
	border-radius: 20px;
	justify-content: center;
	align-items: center;	
	z-index: 1;
	margin-bottom: -150px;
	background-color: #fff;
	
	@media (max-width: 900px) {
		margin: 58px 16px;
		margin-bottom: ${(props: any) => props.index === 2 ? '-120px' : '16px'};
	}
`
const Img = Styled.img`
	margin-top: -50px ;
`
const Button = Styled.button`
	text-transform: uppercase;
	border: none;
	width: 194px;
	min-height: 52px;
	border-radius: 12px;
	color: #fff;
	font-size: 20px;
	font-weight: 700;
	margin: 24px auto; 
	text-align: center; 
	letter-spacing: 3%;

`