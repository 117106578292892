import React from 'react'
import Styled from 'styled-components'

interface Props {
	backgroundColor: string,
	width: string,
	height: string,
}

const Spacer = ({ backgroundColor, width, height}: Props) => {
  return (
	<Space
		backgroundColor={backgroundColor}
		width={width}
		height={height}
	/>
  )
}

export default Spacer

const Space = Styled.div<Props>`
	width: ${(Props: any) => Props.width};
	background-color: ${(Props: any) => Props.backgroundColor};
	height: ${(Props: any) => Props.height};
`