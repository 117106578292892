import { graphql, Link, useStaticQuery } from 'gatsby'
import React, { useState } from 'react'
import styled from 'styled-components'
import SectionContainer from '../shared/SectionContainer'
import SectionTitle from '../shared/SectionTitle'
import ArrowDownReviewButton from '../../assets/svg/ArrowReviewDown.svg'
import TriangleArrowDown from '../../assets/svg/TriangleArrowDown.svg'
import { InsertEmoticon } from '@material-ui/icons'

import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { InputLabel } from '@material-ui/core'

const query = graphql`
  {
    allStrapiSavanoriaiVaikams {
      edges {
        node {
          AtaskaitosAntraste
          Ataskaitos {
            Antraste
            ButtonText
            Parinktys {
              parinktis
              id
			  url
            }
          }
        }
      }
    }
  }
`

const Report = ({ id }: any) => {

	const content = useStaticQuery(query)
	const title = content.allStrapiSavanoriaiVaikams.edges[0].node.AtaskaitosAntraste
	const firstCardData = content.allStrapiSavanoriaiVaikams.edges[0].node.Ataskaitos[0]
	const secondCardData = content.allStrapiSavanoriaiVaikams.edges[0].node.Ataskaitos[1]

	const [selectedValueFirstCard, setSelectedValueFirstCard] = useState<string>(firstCardData.Parinktys[0].url)
	const [selectedValueSecondCard, setSelectedValueSecondCard] = useState<string>(secondCardData.Parinktys[0].url)

	const secontCardData = content.allStrapiSavanoriaiVaikams.edges[0].node.Ataskaitos[1]

	return (
		<>
			<SectionContainer style={{ padding: '32px' }} >
				<div style={{ display: 'flex', flexDirection: 'column' }} id="ataskaitos">
					<SectionTitle> {title}  </SectionTitle>
					<BoxWrapper>				
						<CardWrapper>
							<h3> {firstCardData?.Antraste} </h3 >
							<DropdownBox>
								<DropdownSelect value={selectedValueFirstCard?.url} onChange={(e: any) => setSelectedValueFirstCard(e.target.value)}>
									{
										firstCardData?.Parinktys?.map((item: any, index: number) => {
											return (
												<DropdownItem value={item.url} key={index}>
													{item?.parinktis}
												</DropdownItem>
											)
										})
									}
								</DropdownSelect>
							</DropdownBox>
							<Link to={selectedValueFirstCard} target="_blank" style={{ width: '102px' }}>
								<ButtonReview>
									<img src={ArrowDownReviewButton} />
									{secondCardData?.ButtonText}
								</ButtonReview>
							</Link>
						</CardWrapper>
						<CardWrapper>
							<h3> {secondCardData?.Antraste} </h3 >

							<DropdownBox>
								<DropdownSelect value={selectedValueSecondCard?.url} onChange={(e: any) => setSelectedValueSecondCard(e.target.value)}>
									{
										secontCardData?.Parinktys?.map((item: any, index: number) => {
											return (
												<DropdownItem value={item.url} key={index}>
													{item?.parinktis}
												</DropdownItem>
											)
										})
									}
								</DropdownSelect>
							</DropdownBox>
							<Link to={selectedValueSecondCard} target="_blank" style={{ width: '102px' }}>
								<ButtonReview>
									<img src={ArrowDownReviewButton} />
									{secondCardData?.ButtonText}
								</ButtonReview>
							</Link>
						</CardWrapper>
					</BoxWrapper>
				</div>
			</SectionContainer>
		</>
	)
}

export default Report



const DropdownBox = styled.div`
position: static;
	/* position: relative; */

`
const SelectList = styled.select`
	width: 148px;
	height: 40px;
`

const BoxWrapper = styled.div`
	display: flex;
	gap: 2em;
	flex-direction: row;

	@media (max-width: 800px ) {
		flex-direction: column;
	}


`

const CardWrapper = styled.div`
	display: flex;
	flex-direction: column;
	gap: 2em;
	border: 2px solid #0E558F;
	border-radius: 12px;
	padding: 32px 16px;

	h3 {
		font-size: 24px;
		max-width: 501px;
		min-height: 68px;
		font-weight: 600;
		letter-spacing: 0.5px;
		color: #202020;
		margin: 0;
	}

	@media (max-width: 768px) {
		max-width: 358px;
		min-height: 204px;
		flex:dd

		h3 {
			font-size: 18px;
			font-weight: 500;
			letter-spacing: 0.5px;
		}
	}
`

const ButtonReview = styled.button`
	display: flex;
	width: 100px;
	/* max-width: 102px; */
	min-height: 28px;
	border: none;
	color: #0E558F;
	background-color: white;
	align-items: center;
	font-size: 20px;
	font-weight: 400;
	padding: 0;
	cursor: pointer;

	img {
		width: 15px;
		height: 15px;
		margin-right: 8px;
	}

	@media (max-width: 768px) {
		font-size: 16px;
		font-weight: 500;
	}
`
const DropdownSelect = styled.select`
	padding: 0 16px;
	color: #0E558F;
	max-width: 300px;
	width: 100%;
	height: 48px;
	border-radius: 8px;
	border: 1px solid #0E558F;

`
const DropdownItem = styled.option`

	/* padding: 0.2em 0; */
	/* margin: 0.7em 0.5em; */
	/* margin: 0.2em 0; */
	color: #0E558F;
	background-color: white;
	font-size: 20px;
	/* border: 1px solid #0E558F; */
	font-weight: 400;
	cursor: pointer;




	/* border-bottom: 1px solid #e6e6e6; */
	/* &:hover {
		background-color: #e6e6e6;
	} */
`