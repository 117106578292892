import React, { useState } from 'react'
import Styled from 'styled-components'
import SectionContainer from '../shared/SectionContainer'
import { graphql, Link, useStaticQuery } from 'gatsby'
import Box from '@mui/material/Box';
import styled from 'styled-components'
import { Dialog, DialogContent } from '@material-ui/core'

const query = graphql`
  {
    allStrapiSavanoriaiVaikams {
      edges {
        node {
          PrisidekPrieMusu {
            Antraste
            Nuotrauka {
              localFile {
                publicURL
              }
            }
          }
          SavanoriaiHero {
            colorProp
            id
			url
            nuotrauka {
              localFile {
                publicURL
              }
            }
          }
        }
      }
    }
  }
`

const JoinUs = () => {
	const data = useStaticQuery(query)

	const title = data.allStrapiSavanoriaiVaikams.edges[0].node.PrisidekPrieMusu.Antraste
	const image = data.allStrapiSavanoriaiVaikams.edges[0].node.PrisidekPrieMusu.Nuotrauka.localFile.publicURL
	const savanoriai = data.allStrapiSavanoriaiVaikams.edges[0].node.SavanoriaiHero

	const [open, setOpen] = useState(false)
	const [value, setValue] = useState('')
	const handleOpen = () => setOpen(true);
	const handleClose = () => setOpen(false);

	return (
		<SectionContainer style={{ padding: '32px' }}>
			<Cont>
				<Wrapper>
					<SectionTitle>{title}</SectionTitle>
					<ButtonWrapper >
						<Dialog maxWidth={false} open={open} onClose={handleClose}>
							<DialogContent style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
								<Headeris>Prisidėk prie mūsų!</Headeris>
								<CardWrapper>
									{savanoriai.map((item: any, index: number) => {
										return (
											<Image
												src={item.nuotrauka?.localFile?.publicURL}
												onClick={() => window.open(item.url)}
											/>
										)
									})
									}</CardWrapper>
							</DialogContent>
						</Dialog>
						<Button onClick={handleOpen}>SAVANORIAUK</Button>
						<Link to='/paremk'>
							<Button>PAREMK</Button>
						</Link>
					</ButtonWrapper>
				</Wrapper>
				<Img src={image} />
			</Cont>
		</SectionContainer>
	)
}

export default JoinUs;

const Cont = Styled.div`
	display: flex;
	align-items: center;

	@media 	(max-width: 1055px) {
		flex-direction: column;
		align-items: center;
	}
`

const SectionTitle = Styled.h2`
	font-weight: 600;
	color: #fff;	
	font-size: 48px;
	align-items: start;

	@media 	(max-width: 600px) {
		font-size: 32px;
		align-items: center;
	}
`
const ButtonWrapper = Styled.div`
	display: flex;
	flex-direction: row;
	/* flex-wrap: wrap; */
	max-width: 534px;
	margin-left: auto;
	margin-right: auto;
	gap: 16px;
	/* margin: 0 47px 64px; */

	@media 	(max-width: 600px) {
		flex-direction: column;
		align-items: center;
		gap: 20px;
	}
	
`
const Wrapper = Styled.div`
	display: flex;
	flex-direction: column;
	background-color: #3F8ABB;
	justify-content: space-between;
	border-radius: 12px;
	padding: 48px 32px;
	max-width: 598px;
	min-height: 279px;
	z-index: 5;

	margin-right: -150px;

	@media (max-width: 1200px) {
		margin-right: -150px;
	}

	@media (max-width: 1150px) {
		margin-right: -222px;
		width: 100%;
	}
	@media (max-width: 1055px) {
		margin-right: 0;
		width: 100%;
		gap: 32px;
	}
`
const Button = Styled.button`
	display: flex;
	background-color: #fff;
	font-size: 20px;
	font-weight: 700;
	border: none;
	border-radius: 12px;
	color: #0E558F;
	align-items: center;
	justify-content: center;
	width: 259px; 
	max-height: 52px;
	padding: 20px;
	flex: 1 1 259px;
`

const Img = Styled.img`
	 width: 100%;
	max-width: 684px;
	height: auto;
`

const Headeris = Styled.div`
	font-size: 48px;
	line-height: 140%;

	/* Secondary Blue */
	color: #0E558F;
	text-align: center;
	padding-top: 1.5rem;

	@media (max-width: 1000px) {
		font-size: 28px;
	}
`

const CardWrapper = styled.div`
	display: flex;
	max-width: auto;
	max-height: auto;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	gap: 30px;
	flex-shrink: 1;
	/* justify-content: center; */

	@media (max-width: 900px) {
		flex-direction: column;
		align-items: center;
		max-width: 100%;
		height: auto;
		margin: 32px 16px;
	}
`

const Image = styled.img`
  display: flex;
  flex: 0 0;
  cursor: pointer;
  max-width: 300px;
  height: 100%;
  width: 300px;

  &:last-of-type {
	width: 150px;
  }
`