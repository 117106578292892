import React from 'react'
import styled from 'styled-components';
import ReCAPTCHA from 'react-google-recaptcha';
import SectionContainer from '../shared/SectionContainer'
import { graphql, useStaticQuery } from 'gatsby';

const query = graphql`
  {
	
    allStrapiSavanoriaiVaikams {
      edges {
        node {
          Rekvizitai {
            Antraste
			instagram
			linkedin
			facebook
            Rekvizitai {
              Antraste
              Aprasymas
            }
          }
        }
      }
    }
  }
`

function ContactForm() {
	const data = useStaticQuery(query)
	const title = data.allStrapiSavanoriaiVaikams.edges[0].node.Rekvizitai.Antraste
	const dataRekvizitai = data.allStrapiSavanoriaiVaikams.edges[0].node.Rekvizitai.Rekvizitai
	const socTinklai = data.allStrapiSavanoriaiVaikams.edges[0].node.Rekvizitai;

	return (
		<div id="kontaktai">
			<SectionContainer backgroundColor={'#3F8ABB'} style={{ padding: '48px 16px', display: 'flex', flexWrap: 'wrap', gap: '16px' }}>
				<Text style={{flex: '1'}}>
					<h2 id="kontaktai">{title}</h2>
					{dataRekvizitai.map((item: any, index: number) => {
						return (
							<div key={index}>
								<strong>{item.Antraste} </strong>
								{item.Aprasymas}
							</div>
						)
					})}
				</Text>
				{/* <Form className='contact-form' method="post" action="http://localhost:3000/api/email/sv"> */}
				<Form className='contact-form' method="post" action="/api/email/sv" style={{flex: '1'}}>
					<div style={{display: 'flex', gap: '24px', width: '100%'}}>
						<input
							className='input-name'
							type={'text'} placeholder={'Jūsų vardas'}
							height='100px'
							name="name"
						/>
						<input
							className='input-email'
							type={'email'}
							placeholder={'Jūsų el. paštas'}
							name="email"
						/>
					</div>

					<textarea
						className='input-comment'
						placeholder={'Pasiūlymai, paklausimai'}
						name="question" >
					</textarea>
					<ReWrapper>
						<ReCAPTCHA
							data-size={'compact'}
							className='g-recaptcha'
							sitekey="6Le5io8eAAAAAPScDZ4YSChR0QByo80xxPfkyz61"
						/>
					</ReWrapper>
					<Button>
						Susisiekti
					</Button>
				</Form>
			</SectionContainer>
		</div>
	)
}

export default ContactForm

const Text = styled.div`
	text-align: start;
	color: white;
	font-size: 20px;

	h2 {
		font-size: 48px;
		font-weight: 600;
		text-align: start;
		/* content-align: start; */
	}

	div {
		font-size: 20px;
		font-weight: 400;
	}

	@media (min-widht: 768px) {
		flex: 1 1 565px;
	} 

	@media (max-width: 768px) {
		display: flex;
		flex-direction: column;

		h2 {
			margin-bottom: 19px;
			text-align: center;
			font-size: 32px;
		}
	}
`
const Form = styled.form`
	background: #ffffff; 
	align-items: center;
	align-content: center;
	border-radius: 12px;
	padding: 64px 32px;

	input {
		margin-bottom: 16px;
		padding: 14px 16px;
		border: 1px #D0D1D3 solid;
		border-radius: 12px;
		background-color: white;
		font-size: 16px;
		border: 1px #D0D1D3 solid;
		border-radius: 12px;
		background-color: white;
		font-size: 16px;
		height: 50px;
		flex: 1;
	}
	
	textarea {
		height: 130px;
		width: 100%;
		margin-bottom: 12px;
		padding: 14px 16px;
		border-radius: 12px;
		font-size: 16px;
		border: 1px #D0D1D3 solid;
		overflow: hidden;
	}

	@media (max-width: 768px) {
		display: flex;
		flex-direction: column;
		flex: 0 1 484px;
		width: 100%;
		margin-bottom: 0;
		margin-top: 32px;

		h2 {
			padding: 16px 35px 35px 0;
			height: 84px;
			font-size: 32px;
		}
	}
`
const ReWrapper = styled.div`
	display: flex;
	justify-content: center;
`
const Button = styled.button`
	// height: 52px;
	padding: 16px 0;
	background-color: #0A2540;
	color: #fff;
	border-radius: 12px;
	margin-top: 20px;
	text-transform: upperCase;
	font-weight: 700;
	font-size: 20px;
	width: 100%;
`

