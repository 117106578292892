import React from 'react'
import Card from './Card'
import styled from 'styled-components'
import SectionContainer from '../shared/SectionContainer'
import Spacer from './Spacer'
import { graphql, useStaticQuery } from 'gatsby'

export const query = graphql`
  {
    allStrapiSavanoriaiVaikams {
      edges {
        node {
          HeroTitle
          SavanoriaiHero {
            id
            colorProp
			url
            nuotrauka {
              url
              localFile {
                publicURL
              }
            }
          }
        }
      }
    }
	strapiSavanoriaiVaikams {
		pagrindinis_tekstas
		pagrindine_nuotrauka {
		  localFile {
			publicURL
		  }
		}
	  }
  }
`
const Hero = () => {
	let data = useStaticQuery(query)
	const title = data.allStrapiSavanoriaiVaikams.edges[0].node.HeroTitle
	const savanoriai = data?.allStrapiSavanoriaiVaikams?.edges[0]?.node?.SavanoriaiHero
	return (
		<>
			<SectionContainer style={{ padding: '32px' }} src={data.strapiSavanoriaiVaikams.pagrindine_nuotrauka.localFile.publicURL}>
				{/* <img src={data.strapiSavanoriaiVaikams.pagrindine_nuotrauka.localFile.publicURL} /> */}
				<Wrapper>
					<Title>
						{data.strapiSavanoriaiVaikams.pagrindinis_tekstas}
					</Title>
					<CardWrapper>
						{savanoriai.map((item: any, index: number) => {
							return (
								<Card
									key={index}
									colorProp={item.colorProp}
									img={item.nuotrauka?.localFile?.publicURL}
									btnText={'Plačiau'}
									url={item.url}
								/>
							)
						})
						}
					</CardWrapper>
				</Wrapper>
			</SectionContainer>

			<Spacer backgroundColor={'#3F8ABB'} width={'100%'} height={'184px'} />
		</>
	)
}

export default Hero

const Title = styled.h1`
	font-size: 72px;
	font-weight: 600;
	// color: #0E558F;
	color: #fff;
	text-align: center;
	margin: 170px auto;
	
	@media (max-width: 768px) {
		font-size: 40px;
		margin: 32px 16px;
	}
`

const CardWrapper = styled.div`
	display: flex;
	max-width: auto;
	max-height: auto;
	flex-direction: row;
	justify-content: space-between;
	gap: 30px;
	flex-shrink: 1;


	@media (max-width: 900px) {
		flex-direction: column;
		align-items: center;
		max-width: 100%;
		height: auto;
		margin: 32px 16px;
	}
`
const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
`
